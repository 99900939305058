<template>
  <div>
    <title>SIM INVENTORY ~ BOM ORDER LIST DETAILS</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        BOM Order List Details
        <br />
        <h4>The following is a list of transaction data for the detailed BOM order list</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div class="row">
        <div class="col-md-12">
          <div class="box box-danger">
            <div class="box-header with-border">
              <h3 class="box-title">Filter data</h3>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-3">
                  Show &nbsp; :
                  <select
                    v-model="pageSize"
                    @change="handlePageSizeChange($event)"
                  >
                    <option v-for="size in pageSizes" :key="size" :value="size">
                      {{ size }}
                    </option>
                  </select>
                  results
                </div>
                <div class="col-xs-5">
                  <input
                    type="text"
                    autocomplete="off"
                    placeholder="Search by Item Code"
                    class="form-control"
                    v-model="namesearch"
                  />
                </div>
                <div class="col-xs-1">
                  <button
                    @click="searchdata()"
                    type="button"
                    class="btn btn-info"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
     
      <div class="row">
        <div class="col-xs-12">
          <div class="box box-primary">
            <div class="box-header">
              <!-- <h3 class="box-title">Data Supplier</h3> -->
            </div>

            <!-- /.box-header -->
            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Item Code</th>
                      <th>Qty</th>
                      <th>Unit</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.kode_barang }}</td>
                      <td>{{ classdata.qty }}</td>
                      <td>{{ classdata.unit }}</td>
                      <td>
                        <router-link
                          :to="'/edit-orderbom-detail/' + classdata.id"
                        >
                          <button
                            title="Edit order BOM detail"
                            class="btn btn-info"
                          >
                            <i class="fa fa-edit"></i>
                          </button>
                        </router-link>
                        &nbsp;
                        <button
                          title="Delete order BOM detail"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="nmsrc">
                  <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
                </div>
                <div v-else></div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination,
  },
  data() {
    return {
      isShow: false,
      nmsrc: true,
      loading: false,
      namesearch: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 5,
      pageSizes: [5, 10, 25, 50],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_d_bom/getalldatadaftar_order_d_bom?length="+this.pageSize+"&page="+this.page+"&id_daftar_order_d=" + this.$route.params.id
      const urlApiGETdetail = this.$apiurl + "daftar_order_d_bom/getalldatadaftar_order_d_bom?length="+this.pageSize+"&page="+this.page+"&id_daftar_order_d=" + this.$route.params.id
      axios
        .get(urlApiGETdetail, { headers: headers })
        .then((resp) => {
          this.list_paged = resp.data.data
          this.count = resp.data.data.datatotalcount;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          })
        });
    },
    searchdata() {
      this.loading = true;
      this.nmsrc = true;
      // const params = this.getRequestParams(this.page);
      const paramsst = this.getRequestParams(this.pagestat);
      if (this.namesearch == "") {
        this.$router.push({ name: "detailorderbomview" });
        this.loading = true;
        this.fetchData();
      } else {
        const tokenlogin = sessionStorage.getItem("token");
        // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_d_bom/getalldatadaftar_order_d_bom?length="+this.pageSize+"&cari="+this.namesearch+"&page="+this.page+"&id_daftar_order_d=" + this.$route.params.id
        const urlApiGETdetail = this.$apiurl + "daftar_order_d_bom/getalldatadaftar_order_d_bom?length="+this.pageSize+"&cari="+this.namesearch+"&page="+this.page+"&id_daftar_order_d=" + this.$route.params.id
        axios
            .get(urlApiGETdetail, { paramsst, headers: { Authorization: tokenlogin } })
            .then((resp) => {
                this.list_paged = resp.data.data
                this.count = resp.data.data.datatotalcount;
                this.loading = false;
            })
            .catch((err) => {
                // console.log(err.response);
                swal({
                    icon: "warning",
                    title: "Warning",
                    text: err.response.data,
                })
            });
      }
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          // const urlAPIbrgdel = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_d_bom/hapusdaftar_order_d_bom/" + id;
          const urlAPIbrgdel = this.$apiurl + "daftar_order_d_bom/hapusdaftar_order_d_bom/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin,
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully",
                }).then(function () {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed",
                }).then(function () {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
              })
            });
        } else {
          this.loading = false;
        }
      });
    },

    
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>